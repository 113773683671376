import React from 'react'

const Headerone = () => {
  return (
    
         <header>
            sing board design
         </header>
  
  )
}

export default Headerone