import React from 'react'

const Header = () => {
  return (
<div className='header'>   
         <p>Sun led letters</p>
</div>
  )
}

export default Header