import React from 'react'

const Quotes = () => {
  return (
    <div className='totalQuotes'>
      
      <div className='quotes'>
         <p>Led Neon with a unique design </p>
      </div>
      
      <div className='content'>
            <p>Neon for <br /> your company</p>
      </div>

      <div className='quotes-secound'>
         <p>neon is whatever you want it to be; <br /> a wonderful gift,a cool logos for your business,<br /> or a nice decoration for your home,office or events</p>
      </div>
       

    </div>
  )
}

export default Quotes