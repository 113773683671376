import React from 'react'

const NeonImage = () => {
  return (
    <div>
        <img src="https://i.pinimg.com/236x/ce/bf/84/cebf844380916a8927b1e2bb9908d178.jpg "
            className='neonimage' 
        />
    </div>
  )
}

export default NeonImage